import React from "react";
import logo from "../static/logoTransparent.png";
import "../App.css";

function Start() {
  return (
    <div className="App">
      <img src={logo} className="App-logo" alt="logo" />
      <a href="https://link.portraiture.ai/open">
        <button className="Button-Standard">OPEN PORTRAITURE.AI</button>
      </a>
    </div>
  );
}

export default Start;
