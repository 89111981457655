import React, { useEffect, useState } from "react";
import logo from "../static/logoTransparent.png";
import { firebaseAuth } from "../components/firebase";
import { useSearchParams } from "react-router-dom";
import {
  checkActionCode,
  applyActionCode,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from "firebase/auth";
import LoadingSpinner from "../components/LoadingSpinner";
import "../App.css";

function EmailVerify() {
  const [verified, setVerified] = useState(false);
  const [passwordSuccess, setPasswordSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [paramMode, setParamMode] = useState("");
  const [paramCode, setParamCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordCheck, setNewPasswordCheck] = useState(false);
  const [newPasswordRepeat, setNewPasswordRepeat] = useState("");

  const handleVerify = async () => {
    try {
      setLoading(true);
      if (paramMode === "verifyEmail" && paramCode !== "") {
        await applyActionCode(firebaseAuth, paramCode);
        setVerified(true);
        setLoading(false);
      } else {
        throw new Error("Invalid parameters");
      }
    } catch (error: any) {
      setErrorMessage(error.message);
      setLoading(false);
      console.log(error);
    }
  };

  const handleResetPassword = async () => {
    try {
      setLoading(true);
      if (paramMode === "resetPassword" && paramCode !== "") {
        await confirmPasswordReset(firebaseAuth, paramCode, newPassword);
        setPasswordSuccess(true);
        setLoading(false);
      } else {
        throw new Error("Invalid parameters");
      }
    } catch (error: any) {
      setErrorMessage(error.message);
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,60}$/;
    if (!passwordRegex.test(newPassword)) {
      setNewPasswordCheck(false);
    } else {
      setNewPasswordCheck(true);
    }
  }, [newPassword]);

  useEffect(() => {
    const mode = searchParams.get("mode");
    const actionCode = searchParams.get("oobCode");
    console.log(mode);
    console.log(actionCode);
    if (mode === "verifyEmail" || mode === "resetPassword") {
      setParamMode(mode);
      if (actionCode) {
        if (mode === "resetPassword") {
          verifyPasswordResetCode(firebaseAuth, actionCode)
            .then((email) => {
              setParamCode(actionCode);
              return setLoading(false);
            })
            .catch((error) => {
              setErrorMessage(error.message);
              setLoading(false);
              return console.log(error);
            });
        } else {
          setParamCode(actionCode);
          return setLoading(false);
        }
      } else {
        setErrorMessage("Invalid Parameters");
        setLoading(false);
      }
    } else {
      setErrorMessage("Invalid Parameters");
      setLoading(false);
    }
  }, [searchParams]);

  return (
    <div className="App">
      <img src={logo} className="App-logo" alt="logo" />
      {paramMode === "verifyEmail" ? (
        loading === false ? (
          errorMessage === "" ? (
            verified === false ? (
              <div>
                <button className="Button-Standard" onClick={handleVerify}>
                  VERIFY EMAIL
                </button>
              </div>
            ) : (
              <div>
                <p className="App-Title">EMAIL VERIFIED</p>
                <a href="https://link.portraiture.ai/verify">
                  <button className="Button-Standard">
                    OPEN PORTRAITURE.AI
                  </button>
                </a>
              </div>
            )
          ) : (
            <div>
              <p className="App-Text">{errorMessage}</p>
              <a href="https://link.portraiture.ai/open">
                <button className="Button-Standard">OPEN PORTRAITURE.AI</button>
              </a>
            </div>
          )
        ) : (
          <LoadingSpinner />
        )
      ) : null}
      {paramMode === "resetPassword" ? (
        loading === false ? (
          errorMessage === "" ? (
            passwordSuccess === false ? (
              <div className="App-Inner">
                <p className="App-Text-Small">
                  Your password must be at least 8 characters long and contain
                  at least one numeric digit, one uppercase and one lowercase
                  letter.
                </p>
                <input
                  placeholder="New password"
                  className="Input-Standard"
                  type="password"
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <input
                  placeholder="Repeat new password"
                  className="Input-Standard"
                  type="password"
                  disabled={!newPasswordCheck}
                  onChange={(e) => setNewPasswordRepeat(e.target.value)}
                />
                <button
                  disabled={
                    newPasswordCheck === false ||
                    newPassword !== newPasswordRepeat
                  }
                  className="Button-Standard"
                  onClick={handleResetPassword}
                >
                  RESET PASSWORD
                </button>
              </div>
            ) : (
              <div className="App-Inner">
                <p className="App-Title">RESET PASSWORD SUCCEDED</p>
                <p className="App-Text">
                  Please sign in to Portraiture with your new password.
                </p>
                <a href="https://link.portraiture.ai/reset">
                  <button className="Button-Standard">
                    OPEN PORTRAITURE.AI
                  </button>
                </a>
              </div>
            )
          ) : (
            <div>
              <p className="App-Text">{errorMessage}</p>
              <a href="https://link.portraiture.ai/open">
                <button className="Button-Standard">OPEN PORTRAITURE.AI</button>
              </a>
            </div>
          )
        ) : (
          <LoadingSpinner />
        )
      ) : null}
      {paramMode !== "verifyEmail" && paramMode !== "resetPassword" ? (
        loading === false ? (
          <div>
            <p className="App-Text">{errorMessage}</p>
            <a href="https://link.portraiture.ai/open">
              <button className="Button-Standard">OPEN PORTRAITURE.AI</button>
            </a>
          </div>
        ) : (
          <LoadingSpinner />
        )
      ) : null}
    </div>
  );
}

export default EmailVerify;

/*
{loading === true ? (
        <p className="App-Title">VERIFYING EMAIL</p>
      ) : (
        <>
          <p className="App-Title">
            {verified === true ? "EMAIL VERIFIED" : "ERROR"}
          </p>

          <p className="App-Text">
            {verified === true
              ? "Your email has been verified. You can now close this window and start unleash your creativity with Portraiture.ai!"
              : errorMessage}
          </p>
        </>
      )}
      */
