import React from 'react';
import logo from './static/logoTransparent.png';import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Start from "./views/Start";
import EmailVerify from "./views/EmailVerify";

import './App.css';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Start />} />
        <Route path="email/hook" element={<EmailVerify />} />
        <Route path="*" element={<Start />} />
      </Routes>
    </div>
  );
}

export default App;
